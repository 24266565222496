import axios from "axios";
const server_url = "https://server.msosidrop.co.tz";

export const order_api = axios.create({
  baseURL: `${server_url}:5300/api`,
  timeout: 10000,
});

export const dropper_api = axios.create({
  baseURL: `${server_url}:5700/api`,
  timeout: 10000,
});
