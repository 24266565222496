import React, { useState } from "react";
import Cookies from "js-cookie";
import logo from "../../assets/imgs/logo.png";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { loginAdmin } from "../../controllers";
import "../style.css";

const Login = () => {
  const navigate = useNavigate();
  const [isSubmiting, setSubmiting] = useState(false);
  const [err, setErr] = useState(null);

  const onFinish = async (values) => {
    try {
      const { phone, password } = values;
      setSubmiting(true);
      const response = await loginAdmin({ phone, password });
      setSubmiting(false);
      if (response.status !== 200) {
        return setErr(response.data.message);
      }
      if (!response.data.is_active) {
        return setErr(
          "You are not allowed to use the system at the moment, Please contact the Administration for account updates"
        );
      }
      Cookies.set("authDropper", JSON.stringify(response.data), {
        expires: 0.5,
      });
      message.success("Welcome back");
      return navigate("/");
    } catch (error) {
      setSubmiting(false);
      console.log({ error });
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} className="logoLogin" resizeMode="contain" />
        <Form
          size="large"
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your Phone!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Phone"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Forgot password?
            </a>
          </Form.Item>
          {err ? (
            <Form.Item>
              <p style={{ fontSize: 13, color: "red" }}>{err}</p>
            </Form.Item>
          ) : null}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={isSubmiting}
              block
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        <p style={{ fontSize: 11, opacity: 0.3 }}>
          MsosiDropper Third-Party {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};
export default Login;
