import React from "react";
import AppLayout from "./Layout";

const Revenue = () => (
  <AppLayout>
    <p>Coming soon</p>
  </AppLayout>
);

export default Revenue;
