import React from "react";
import AppLayout from "./Layout";

const Droppers = () => (
  <AppLayout>
    <p>Hapa ni Droppers</p>
  </AppLayout>
);

export default Droppers;
