import React, { useEffect, useState } from "react";
import AppLayout from "./Layout";
import {
  Card,
  Space,
  Table,
  Tag,
  DatePicker,
  Avatar,
  Col,
  Divider,
  Drawer,
  List,
  Row,
} from "antd";
import { getMerchantOrders } from "../controllers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./style.css";

const { RangePicker } = DatePicker;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [page, setpage] = useState(0);
  const [range, setRange] = useState([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);
  const [open, setOpen] = useState(false);

  const showDrawer = (e) => {
    setSelectedOrder(e);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const auth = Cookies.get("authDropper");
    if (!auth) {
      return navigate("/login");
    }
    const authObj = JSON.parse(auth);
    console.log({ authObj });

    getMerchantOrders(authObj.merchant._id, range, 50, page).then((res) => {
      if (res.status === 200) {
        let list = [];
        res.data?.map((i, one) => list.push({ ...i, ...{ i: one } }));
        setOrders(list.filter((v) => v.courier !== null && v.courier));
      }
    });
  }, [range]);

  const findColor = (order) => {
    let color = "blue";
    order.status === "delivered" ? (color = "green") : (color = "orange");

    return color;
  };

  const styles = {
    mb: { marginBottom: -20 },
  };

  const columns = [
    {
      render: (record) => (
        <React.Fragment>
          {record.i + 1}
          <br />
          {record.order_no}
          <br />
          {record.shop?.name}
          <br />
          {record.buyer?.first_name}
          <br />
          <a
            target="_blank"
            href={`https://maps.google.com/?q=${record.address?.lat},${record.address?.long}`}
          >
            {record.address?.street?.split(",")[1]}
          </a>
          <br />
          <p>
            TZS {(record.courier_tip + record.shipping_fee).toLocaleString()}
          </p>
          {new Date(record.createdAt).toDateString()}
          <br />
          {<Tag color={findColor(record)}>{record.status.toUpperCase()}</Tag>}
        </React.Fragment>
      ),
      responsive: ["xs"],
    },
    {
      title: "SN",
      key: "sn",
      render: (_) => <p>{_.i}</p>,
      responsive: ["sm"],
    },
    {
      title: "ORDER NO",
      key: "order_no",
      render: (_, d) => <a onClick={() => showDrawer(d)}>{d.order_no}</a>,
      responsive: ["sm"],
    },
    {
      title: "DATE",
      key: "date",
      render: (_, d) => <p>{new Date(d.createdAt).toDateString()}</p>,
      responsive: ["sm"],
    },
    {
      title: "FROM",
      key: "shop.name",
      render: (_, { shop }) => <p>{shop.name}</p>,
      responsive: ["sm"],
    },
    {
      title: "TO",
      key: "buyer.first_name",
      render: (_, { buyer, guest }) => (
        <p>{buyer?.first_name ? buyer?.first_name : guest?.name}</p>
      ),
      responsive: ["sm"],
    },
    {
      title: "ADDRESS",
      key: "address",
      render: (_, d) => (
        <a
          target="_blank"
          href={`https://maps.google.com/?q=${d.address?.lat},${d.address?.long}`}
        >
          {d.address?.street?.split(",")[1]}
        </a>
      ),
      responsive: ["sm"],
    },
    {
      title: "FEE",
      key: "amount",
      render: (_, d) => (
        <p>TZS {(d.courier_tip + d.shipping_fee).toLocaleString()}</p>
      ),
      responsive: ["sm"],
    },
    {
      title: "MODE",
      key: "mode",
      render: (_, d) => (
        <Tag color={d.pay_method === "cash" ? "blue" : "purple"}>
          {d.pay_method}
        </Tag>
      ),
      responsive: ["sm"],
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (_, order) => (
        <>
          <Tag color={findColor(order)} key={_}>
            {order.status.toUpperCase()}
          </Tag>
        </>
      ),
      responsive: ["sm"],
    },
    {
      title: "DROPPER",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record.courier ? (
            <Tag color="green" size="small">
              {record.courier.first_name} {record.courier.last_name}
            </Tag>
          ) : (
            <a>Asign Dropper</a>
          )}
        </Space>
      ),
      responsive: ["sm"],
    },
  ];

  return (
    <AppLayout>
      <>
        <div style={{ margin: "20px 0" }}>
          <Card
            title="Orders"
            extra={<RangePicker onCalendarChange={(n, da) => setRange(da)} />}
          >
            <Table size="small" columns={columns} dataSource={orders} />
          </Card>
        </div>
        {selectedOrder ? (
          <Drawer
            width="50%"
            placement="right"
            closable={false}
            onClose={onClose}
            open={open}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                className="site-description-item-profile-p"
                style={{
                  marginBottom: 24,
                }}
              >
                ORDER DETAILS
              </p>
              <div
                style={{
                  marginTop: -15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tag
                  size="small"
                  color={
                    selectedOrder.pay_method === "cash" ? "blue" : "purple"
                  }
                >
                  {selectedOrder.pay_method.toUpperCase()}
                </Tag>
                <Tag color={findColor(selectedOrder)} size="small">
                  {selectedOrder?.status.toUpperCase()}
                </Tag>
              </div>
            </div>

            <p className="site-description-item-profile-p">Basics</p>
            <Row style={styles.mb}>
              <Col span={12}>
                <DescriptionItem
                  title="ORDER NUMBER"
                  content={selectedOrder?.order_no}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="PICKUP NUMBER"
                  content={selectedOrder?.pickup_no}
                />
              </Col>
            </Row>
            <Row style={styles.mb}>
              <Col span={12}>
                <DescriptionItem
                  title="CUSTOMER"
                  content={
                    selectedOrder?.buyer
                      ? `${selectedOrder?.buyer?.first_name} ${selectedOrder?.buyer?.last_name}`
                      : `${selectedOrder?.guest?.name}`
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="PHONE"
                  content={
                    selectedOrder?.buyer
                      ? `+${selectedOrder?.buyer?.phone}`
                      : `${selectedOrder?.guest?.phone}`
                  }
                />
              </Col>
            </Row>

            <Row style={styles.mb}>
              <Col span={12}>
                <DescriptionItem
                  title="PAY METHOD"
                  content={selectedOrder?.pay_method.toUpperCase()}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="TOTAL ORDER COST"
                  content={`TZS ${selectedOrder?.total_cost.toLocaleString()}`}
                />
              </Col>
            </Row>
            <Row style={styles.mb}>
              <Col span={24}>
                <DescriptionItem
                  title="Message"
                  content={
                    selectedOrder?.pay_method === "cash"
                      ? "Please collect cash on the time of delivery. (Total cost)"
                      : "All payments will be done electronically"
                  }
                />
              </Col>
            </Row>
            <Divider />
            <p className="site-description-item-profile-p">Store & Items</p>
            <Row style={styles.mb}>
              <Col span={12}>
                <DescriptionItem
                  title="SHOP"
                  content={selectedOrder?.shop?.name}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="PHONE"
                  content={`+${selectedOrder?.shop?.customerService}`}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="ADDRESS"
                  content={`${selectedOrder?.shop?.address?.street}, ${selectedOrder?.shop?.address?.ward}`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="LOCATION"
                  content={
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${selectedOrder?.shop?.loc?.coordinates[0]},${selectedOrder?.shop?.loc?.coordinates[1]}`}
                    >
                      See on map
                    </a>
                  }
                />
              </Col>
            </Row>
            <List
              dataSource={selectedOrder.items}
              bordered
              size="small"
              renderItem={(item) => (
                <List.Item
                  key={item._id}
                  actions={[<p>x{item.total_counts}</p>]}
                  size="small"
                >
                  <List.Item.Meta
                    title={<p>{item.product?.name}</p>}
                    description={
                      <div>
                        {item.options?.map((option) => (
                          <p key={option._id}>{option.title}</p>
                        ))}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
            <Divider />
            <p className="site-description-item-profile-p">Delivery</p>
            <Row style={styles.mb}>
              <Col span={12}>
                <DescriptionItem
                  title="DELIVERY FEE"
                  content={`TZS ${selectedOrder.shipping_fee.toLocaleString()}`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="DROPPER TIP"
                  content={`TZS ${selectedOrder.courier_tip.toLocaleString()}`}
                />
              </Col>
            </Row>
            <Row style={styles.mb}>
              <Col span={12}>
                <DescriptionItem
                  title="LATITUDE"
                  content={selectedOrder.address?.lat}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="LONGTUDE"
                  content={selectedOrder.address?.long}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DescriptionItem
                  title="LOCATION"
                  content={
                    <a
                      target="_blank"
                      href={`https://maps.google.com/?q=${selectedOrder.address?.lat},${selectedOrder.address?.long}`}
                    >
                      {selectedOrder.address?.street}
                    </a>
                  }
                />
              </Col>
            </Row>
          </Drawer>
        ) : null}
      </>
    </AppLayout>
  );
};

export default Orders;
