import { dropper_api, order_api } from "./api";

export const getMerchantOrders = async (merchantId, range, limit, page) => {
  try {
    const response = await order_api.get(
      `/order/merchant/${merchantId}?page=${page}&limit=${limit}&start=${range[0]}&end=${range[1]}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const loginAdmin = async (body) => {
  try {
    const response = await dropper_api.post(`/admin/login/`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getMerchant = async (id) => {
  try {
    const response = await dropper_api.get(`/merchant/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};
