import React, { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Layout, Menu, Avatar, Card, message, Popconfirm } from "antd";
import logo from "../../assets/imgs/white.png";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants";
import Cookies from "js-cookie";
import { getMerchant } from "../../controllers";
import "../style.css";

const { Header, Content, Footer } = Layout;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [signout, setSignOut] = useState(false);

  useEffect(() => {
    const auth = Cookies.get("authDropper");

    if (!auth) {
      return navigate("/login");
    }
    const authObj = JSON.parse(auth);
    if (!authObj.is_active) {
      return Cookies.remove("auth");
    }
    setAdmin(authObj);

    getMerchant(authObj?.merchant?._id)
      .then((resp) => {
        if (resp.status === 200) {
          setMerchant(resp.data);
        }
      })
      .catch((e) => console.log(e));
  }, [signout]);

  const confirm = (e) => {
    Cookies.remove("auth");
    message.success("Signed out");
    setSignOut(true);
  };
  const cancel = (e) => {
    console.log(e);
  };

  const styles = {
    align: {
      display: "flex",
      alignItems: "baseline",
      padding: 0,
      margin: 0,
    },
    text: {
      padding: 0,
      marginLeft: 5,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold",
    },
    clm: {
      width: "33%",
    },
    head: {
      fontWeight: "bold",
      fontSize: 11,
      opacity: 0.6,
      margin: 0,
    },
    val: {
      fontWeight: "bold",
      fontSize: 12,
      margin: 0,
    },
  };

  return (
    <>
      <Layout className="layout">
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: colors.primary,
          }}
        >
          <div
            className="demo-logo"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img src={logo} style={{ height: 50 }} />
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            items={[
              { key: 1, label: "Orders" },
              // { key: 2, label: "Droppers" },
              { key: 3, label: "Revenue" },
            ]}
            style={{
              width: "20%",
              fontWeight: "bold",
              color: "white",
              alignItems: "center",

              backgroundColor: colors.primary,
            }}
            onClick={(e) => {
              console.log({ e });
              if (e.key === "1") {
                return navigate("/");
              }
              if (e.key === "2") {
                return navigate("/droppers");
              }
              if (e.key === "3") {
                return navigate("/revenue");
              }
            }}
          />
          <Popconfirm
            title="Sign out"
            description="You are about to sign out"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Sign out"
            cancelText="No"
          >
            <div className="adminName">
              <Avatar
                style={{ backgroundColor: colors.secondary, marginRight: 3 }}
                icon={<UserOutlined />}
              />
              {admin?.name}
            </div>
            <Avatar className="avata" icon={<UserOutlined />} />
          </Popconfirm>
        </Header>
        <Content className="contents">
          <Card title="Profile">
            <div className="topDetails">
              <div className="detail">
                <p style={styles.head}>TILL NUMBER</p>
                <p style={styles.val}>{merchant?.merchant_no}</p>
              </div>
              <div className="detail">
                <p style={styles.head}>MERCHANT NAME</p>
                <p style={styles.val}>{merchant?.name.toUpperCase()}</p>
              </div>
              <div className="detail">
                <p style={styles.head}>MERCHANT PHONE</p>
                <p style={styles.val}>+{merchant?.phone}</p>
              </div>
            </div>
            <div className="topDetails">
              <div className="detail">
                <p style={styles.head}>MERCHANT BANK</p>
                <p style={styles.val}>
                  {merchant?.wallet?.network} - {merchant?.wallet?.account_no}
                </p>
              </div>
              <div className="detail">
                <p style={styles.head}>TILL BALANCE</p>
                <p style={{ ...styles.val, color: "green" }}>
                  TZS {merchant?.balance?.toLocaleString()}
                </p>
              </div>
              <div className="detail">
                <p style={styles.head}>TILL RETURNS</p>
                <p style={{ ...styles.val, color: "red" }}>TZS 0</p>
              </div>
            </div>
          </Card>
          {children}
        </Content>
        <Footer
          style={{
            textAlign: "center",
            backgroundColor: colors.dark,
            color: "white",
          }}
        >
          Msosidrop ©{new Date().getFullYear()}. MsosiDropper Third-Party
        </Footer>
      </Layout>
    </>
  );
};

export default AppLayout;
